

































import { Component, Vue, ModelSync } from "vue-property-decorator";
import { ComboBoxItem, WaterMeterForm } from "@/interfaces";
import { cpTimeRangesPieConfig } from "@/pages/piezometry/cppiezometersconfig";

@Component
export default class WaterMeterFilterForm extends Vue {
    @ModelSync("form", "change", {
        type: Object,
        default: {
            valid: true,
            layerAqfrs: true,
            layerUndrgrnd: true,
            layerWM: true
        }
    })
    formValues!: WaterMeterForm;

    ranges: ComboBoxItem[] = cpTimeRangesPieConfig.map(trc => {
        return {
            text: this.$t(`CPPiezometerFilterForm.${trc.range}`),
            value: trc.range
        };
    });

    onFilterUpdated() {
        this.$emit("on-filter-updated");
    }
}
